<!-- 可视化界面——气象数据 -->
<template>
	<div id="vWeather">
		<div class="left">
			<i class="el-icon-sunny"></i>
			<span>晴朗</span>
		</div>
		<div class="right">
			<p><span>●</span>温度：15℃</p>
			<p><span>●</span>风力：三级 风向：偏东风</p>
			<p><span>●</span>空气质量：轻度污染</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		}
	}
</script>

<style lang="scss">
	#vWeather {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;

		.left {
			width: 110px;
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			i {
				font-size: 36px;
				margin-bottom: 8px;
				color: rgba($color: $color_white, $alpha: 0.9);
			}
		}

		.right {
			flex: auto;

			span {
				margin-right: 8px;
				color: #157BEC;
			}
		}
	}
</style>
