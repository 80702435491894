<!-- 可视化界面——近一小时数据 -->
<template>
	<div id="vRecentData">
		<div id="chartRecentData" class="c-chart"></div>
	</div>
</template>

<script>
	export default {
		methods: {
			drawLine(item) {
				let datas = item ? [Math.floor(Math.random() * 2000), Math.floor(Math.random() * 2000), Math.floor(
					Math.random() * 2000), Math.floor(Math.random() * 2000), Math.floor(Math
					.random() * 2000), Math.floor(Math.random() * 2000), Math.floor(Math
					.random() * 2000)] : [900, 1800, 1500, 1200, 2000, 1000, 900];
				let chartRecentData = this.echarts.init(document.getElementById('chartRecentData'), 'echarts_darken');
				let option = {
					color: '#0C78F9',
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					},
					yAxis: {
						type: 'value'
					},
					grid: {
						top: 20,
						left: 60,
						right: 20,
						bottom: 30
					},
					toolbox: {
						feature: {
							magicType: {
								type: ['line', 'bar']
							},
							saveAsImage: {}
						}
					},
					series: [{
						data: datas,
						type: 'line',
						areaStyle: {
							// color: new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							// 	offset: 0,
							// 	color: 'rgba(58,77,233,0.9)'
							// }, {
							// 	offset: 1,
							// 	color: 'rgba(58,77,233,0.1)'
							// }])
							// 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
							color: {
								type: 'linear',
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [{
									offset: 0,
									color: 'rgba(58,77,233,0.9)' // 0% 处的颜色
								}, {
									offset: 1,
									color: 'rgba(58,77,233,0.1)' // 100% 处的颜色
								}],
								global: false // 缺省为 false
							},
							// 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
							// color: {
							//     type: 'radial',
							//     x: 0.5,
							//     y: 0.5,
							//     r: 0.5,
							//     colorStops: [{
							//         offset: 0, color: 'red' // 0% 处的颜色
							//     }, {
							//         offset: 1, color: 'blue' // 100% 处的颜色
							//     }],
							//     global: false // 缺省为 false
							// }
						},
					}]
				};
				chartRecentData.setOption(option);
				window.addEventListener("resize", function() {
					chartRecentData.resize();
				});
			}
		},
		mounted() {
			this.$parent.setEchartsTheme();
			this.drawLine();
		}
	}
</script>

<style lang="scss">
	#vRecentData {
		height: 100%;
	}
</style>
