<!-- 可视化界面——设备状态统计图表 -->
<template>
	<div id="vDeviceCount">
		<div id="chartDeviceCount" class="c-chart"></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.$parent.setEchartsTheme();
			let datas = [{
					value: 180,
					name: '在线'
				},
				{
					value: 58,
					name: '离线'
				},
				{
					value: 21,
					name: '断电'
				}
			];
			let chartDeviceCount = this.echarts.init(document.getElementById('chartDeviceCount'), 'echarts_darken');
			let option = {
				color: ['#2EA9F9', '#12B188', '#C87058'],
				title: {
					text: '总数',
					subtext: 259,
					textStyle: {
						fontSize: 30,
						color: '#CCCCCC'
					},
					subtextStyle: {
						fontSize: 24,
						color: '#CCCCCC'
					},
					top: '36%',
					x: 'center',
					y: 'center',
				},
				legend: {
					orient: 'vertical',
					left: 'right'
				},
				tooltip: {
					trigger: 'item'
				},
				series: [{
					type: 'pie',
					radius: ['50%', '80%'],
					data: datas,
				}]
			};
			chartDeviceCount.setOption(option);
			window.addEventListener("resize", function() {
				chartDeviceCount.resize();
			});
		}
	}
</script>

<style lang="scss">
	#vDeviceCount {
		height: 100%;
	}
</style>
