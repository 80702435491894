<template>
	<div id="vChart3">
		<div id="chart3" class="c-chart"></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			let chart3 = this.echarts.init(document.getElementById('chart3'), 'echarts_darken');
			let option = {
				color: ['#1FADAE'],
				radar: {
					// shape: 'circle',
					name: {
						textStyle: {
							color: '#fff',
							backgroundColor: '#999',
							borderRadius: 3,
							padding: [3, 5]
						}
					},
					indicator: [{
							name: '销售',
							max: 6500
						},
						{
							name: '管理',
							max: 16000
						},
						{
							name: '信息技术',
							max: 30000
						},
						{
							name: '客服',
							max: 38000
						},
						{
							name: '研发',
							max: 52000
						},
						{
							name: '市场',
							max: 25000
						}
					],
					name: {
						formatter: '【{value}】',
						textStyle: {
							color: '#72ACD1'
						}
					},
				},
				series: [{
					type: 'radar',
					areaStyle: {
						normal: {}
					},
					data: [{
						value: [4300, 8000, 22000, 30000, 45000, 16000],
					}]
				}]
			};
			chart3.setOption(option);
			window.addEventListener("resize", function() {
				chart3.resize();
			});
		}
	}
</script>

<style lang="scss">
	#vChart3 {
		height: 100%;
	}
</style>
