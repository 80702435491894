<!-- 可视化界面——实时数据 -->
<template>
	<div id="vRealData">
		<div class="item" v-for="item of dataArr" @click="itemClick(item)">
			<span><i>{{item.title.substring(0,1)}}</i></span>
			<span>{{item.title}} ({{item.unit}})</span>
			<span>{{item.value}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataArr: []
			}
		},
		methods: {
			itemClick(item) {
				this.$emit('sensorItemClick', item);
			}
		},
		mounted() {
			let datas1 = this.common.cGetDeviceData()[0].datas;
			let datas2 = this.common.cGetDeviceData()[1].datas;
			this.dataArr = datas1.concat(datas2);
		}
	}
</script>

<style lang="scss">
	#vRealData {
		height: 100%;

		.item {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			padding: 10px;
			border-bottom: 1px solid darken($color: #117AF2, $amount: 30%);

			&:hover {
				cursor: pointer;
				background-color: rgba($color: #1781ED, $alpha: 0.1)
			}

			span {
				flex: 1;
				text-align: center;
				color: darken($color: $color_white, $amount: 10%);

				i {
					display: inline-block;
					width: 25px;
					line-height: 25px;
					font-style: normal;
					border-radius: 50%;
					background-color: #117AF2;
				}

				&:last-child {
					font-weight: bold;
					color: $color_white;
				}
			}
		}
	}
</style>
