<template>
	<div id="vChart1">
		<div id="chart1" class="c-chart"></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			let chart1 = this.echarts.init(document.getElementById('chart1'), 'echarts_darken');
			let option = {
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					// data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎']
				},
				grid: {
					top: 20,
					left: 60,
					right: 20,
					bottom: 30
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
				},
				yAxis: {
					type: 'value'
				},
				series: [{
						name: '邮件营销',
						type: 'line',
						stack: '总量',
						data: [120, 132, 101, 134, 90, 230, 210]
					},
					{
						name: '联盟广告',
						type: 'line',
						stack: '总量',
						data: [220, 182, 191, 234, 290, 330, 310]
					},
					{
						name: '视频广告',
						type: 'line',
						stack: '总量',
						data: [150, 232, 201, 154, 190, 330, 410]
					},
					// {
					// 	name: '直接访问',
					// 	type: 'line',
					// 	stack: '总量',
					// 	data: [320, 332, 301, 334, 390, 330, 320]
					// }
				]
			};
			chart1.setOption(option);
			window.addEventListener("resize", function() {
				chart1.resize();
			});
		}
	}
</script>

<style lang="scss">
	#vChart1 {
		height: 100%;
	}
</style>
